import React from "react";
import Layout from "../../components/Layout/Layout";

const NotFound = () => {
  return (
    <Layout>
      <div className="not-found">
        <h1>Page Not Found</h1>
      </div>
    </Layout>
  );
};
export default NotFound;
